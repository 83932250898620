import React,{useRef, useState, useEffect} from 'react'
import PopupWindow from '../utils/PopupWindow';
import fbImg from "../../assets/img/icons/social/facebook-icon.svg";
import linkedinImg from "../../assets/img/icons/social/linkedin-icon.svg";
import twitterImg from "../../assets/img/icons/social/twitter-icon.svg";
import { isMobile, isTablet } from 'react-device-detect';

function SocialMedia({pageUrl, pageTitle, endDivPosition}) {
  const container = useRef(null)
  const [sticky,setSticky] = useState(false)
  const [position,setPosition] = useState(false)

  const updatePosition = ()=> {
    let position = window.pageYOffset
    setSticky(position > 200 && endDivPosition > 200 ? true : false)
    setPosition(position)
  }

  useEffect(() => {
    if (isMobile || isTablet){
      return
    }

    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[position]);

  const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`
  const twitterUrl = `https://twitter.com/share/?url=${pageUrl}&text=${pageTitle}`
  const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}?latest`
  return (
    <div className={`social-media-articles ${sticky ? 'fixed':''}`} ref={container}>
      <ul className='social-ul'>
        <li className='social-li'>
          <div className='link-div facebook' aria-hidden onClick={()=>PopupWindow(fbUrl, pageTitle)}>
            <img
              className='fb-icon'
              src={fbImg}
              alt='Share on Facebook'
              title='Share on Facebook'
            />
          </div>
        </li>
        <li className='social-li'>
          <div  className='link-div twitter' aria-hidden onClick={()=>PopupWindow(twitterUrl, pageTitle)}>
            <img src={twitterImg} title='Share on Twitter' alt='Share on Twitter' />
          </div>
        </li>
        <li className='social-li'>
          <div className='link-div linkedin' aria-hidden onClick={()=>PopupWindow(linkedInUrl, pageTitle)}>
            <img src={linkedinImg} title='Share on LinkedIn' alt='Share on LinkedIn' />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default SocialMedia
