const articlesList = [
  {
    title: 'How to Impress Your Visitors with a Sophisticated Safety Induction on the Life-Saving Ruless',
    slug:'impress-visitors-sophisticated-safety-induction-life-saving-rules'
  },
  {
    title: 'Will newly-developed VR training for drivers help reduce fatal road incidents in the Middle East?',
    slug:'vr-training-reduce-fatal-road-incidents-middle-east'
  },
  {
    title: 'New and Innovative Way to Train Contractor Workers on Fatal HSE Risks.',
    slug:'innovative-way-train-workers-fatal-hse-risk'
  },
  {
    title: '5 Reasons Why Companies Should Consider VR as a Learning Tool',
    slug:'5-reasons-why-companies-should-consider-vr-learning-tool'
  },
  {
    title: 'VR Games: A New Way to Create Significant Safety Impact',
    slug:'vr-games-new-way-create-significant-safety-impact'
  },
  {
    title: 'VR Games: A New Way to Make Work Significantly Safer',
    slug:'vr-games-new-way-make-work-significantly-safer'
  },
]

export default articlesList;