import React,{useState, useEffect, useRef} from 'react'
// import React from 'react'
import { Link } from 'gatsby'
import logo from '../../assets/img/virsat-logo.svg'
import arcticleList from '../json/articles'
import newsList from '../json/news'
import { isMobile, isTablet } from 'react-device-detect';
import phoneImg from '../../assets/img/icons/phone-light.svg'
import emailImg from '../../assets/img/icons/email-light.svg'

const ArticleSidebar = ({slug, endDivPosition, type='articles', stickyCta='subscribe'}) => {
  const newsletterSticky = useRef(null)
  const [position, setPosition] = useState(0);
  const [nlFixed, setNlFixed] = useState(false);
  const [articleType, setArticleType] = useState({'code': type, 'name': 'Articles', 'list': arcticleList});

  const updatePosition = () => {
    const position = newsletterSticky.current.getBoundingClientRect().top
    setPosition(position)

    setNlFixed(position < 80 && endDivPosition > 300 ? true : false)
  };

  useEffect(() => {
    switch (type) {
      case 'news':
        setArticleType({'code': type, 'name': 'News', 'list': newsList})
        break;
    
      default:
        setArticleType({'code': type, 'name': 'Articles', 'list': arcticleList})
    }
    console.log(articleType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (isMobile || isTablet){
      return
    }

    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[position]);

  return (
    <aside className={`column article-sidebar`}>
      <div className='sidebar-box center'>

        <div className="main-subtitle center dark large">Subscribe to Newsletter</div>
        <p className='center'>
        Discover the latest innovation, new products released, offers and promotions, and upcoming events.
        </p>
        <Link className='button-link full-width center' to="/lp/newsletter/">Subscribe</Link>
      </div>

      <div className='sidebar-box center'>
        <img className='site-logo' src={logo} alt="VIRSAT Logo"/>

        <div className="title center">About VIRSAT</div>
        <p className='center'>
          Bonded through their common passion for developing safety
          strategies and technological innovations, Willem, Dick, and Bassam
          have established a company called{" "}
          <span className='primary-color'>VIRSAT</span> which stands for{" "}
          <span className='primary-color'>VI</span>rtual{" "}
          <span className='primary-color'>R</span>eality{" "}
          <span className='primary-color'>SA</span>fety{" "}
          <span className='primary-color'>T</span>raining.
        </p>
        <Link className='button-link full-width center' to="/about/">Learn more</Link>
      </div>
      {articleType.list.length > 1 && (
        <div className='sidebar-box'>
          <div className="title">Recent {articleType.name}</div>
          <ul>
            {articleType.list.map(li=>{
              if (slug[0] !== li.slug) {
                return (
                  <li key={li.slug}>
                    <Link to={`/articles/${li.slug}`}>
                      {li.title}
                    </Link>
                  </li>
                )
              } else {
                return null
              }
            })}
            
          </ul>
          <Link className='button-link full-width center' to="/articles/">View All {articleType.name}</Link>
        </div>
      )}
      <div style={{height:'1px','width':'1px'}} ref={newsletterSticky}/>
      <div className={`sticky-widget ${nlFixed ? 'fixed':''}`}>
        {stickyCta === 'subscribe' && (
          <div className={`sidebar-box center cta`}>
            <div className="main-subtitle center dark large">Subscribe to Newsletter</div>
            <p className='center'>
            Discover the latest innovation, new products released, offers and promotions, and upcoming events.
            </p>
            <Link className='button-link full-width center' to="/lp/newsletter/">Subscribe</Link>
          </div>
        )}
        {stickyCta === 'email_phone' && (
          <div className={`sidebar-box center cta driving-simulator`}>
            <h3 style={{"color": "white"}}>Contact us</h3>
            <ul>
              <li>
                
                <a href="tel:+97126436640">
                  <img src={phoneImg} alt="Phone"/>

                  +971 2 643 6640
                  </a>
              </li>
              <li>
                
                <a href="mailto:info@virsat.com"><img src={emailImg} alt="email"/> <span>info@virsat.com</span></a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </aside>
  )
}

export default ArticleSidebar
