import React from 'react'

const ImagePreview = ({img, orientation="landscape", onClick, show}) => {
  
  return (
    <div className={`image-preview ${!show && 'hide-me'}`} onClick={onClick} aria-hidden>
      <div className="wrapper">
        {img !== "false" && (
          <img className={`${orientation} ${show && 'show-me'}`} src={img} alt="Preview in full size"/>
        )}
      </div>
      <button onClick={onClick}>Close</button>
    </div>
  )
}

export default ImagePreview
